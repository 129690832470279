import React, { useState } from 'react'
import plusIcon from '../../assets/images/plus-icon.jpg'
import tickMark from '../../assets/images/tick-mark.png'
import chatIcon from '../../assets/images/chat-icon.svg'
import moment from 'moment'
import { createUUID } from '../../utils'
import Comments from './Comments'


const Item = ({ item }) => {
  const [commentIsOpen, setCommentIsOpen] = useState(false)

  return (
    <li>
      <div className="col_1">
        {item.name}
        <span className="chat-icon">
          {item.updates.length ? (
            <>
              <img
                onClick={() => setCommentIsOpen(true)}
                src={chatIcon}
                width={28}
                alt="chat-icon"
                style={{ cursor: "pointer" }}
              />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setCommentIsOpen(true)}
                className="badge"
              >
                {item.updates.length}
              </span>
            </>
          ) : (
            <img src={plusIcon} alt="plus" />
          )}
        </span>
      </div>
      {item.column_values?.map((cv, index) => {
        if (cv.type === "status" && cv.title === "Status") {
          let className = 'bg-color2'
          switch (cv.text) {
            case "Voltooid": {
              className = 'bg-color1'
              break;
            }
            case "In productie": {
              className = 'bg-color2'
              break;
            }
            case "In afwachting": {
              className = 'bg-color3'
              break;
            }
            case "Vastgelopen": {
              className = 'bg-color4'
              break;
            }
            default: {
              className = 'bg-color-default'
            }
          }
          return (
            <div key={createUUID()} className={`col_${index + 2} ${className}`}>{cv.text}</div>
          )
        }
        else if (cv.type === "timeline") {
          const start = moment(cv.text.slice(0, 10), "YYYY-MM-DD").format("DD MMM")
          const end = moment(cv.text.slice(-10), "YYYY-MM-DD").format("DD MMM")
          const formatted = `${start} - ${end}`
          return (
            <div key={createUUID()} className={`col_${index + 2}`}><span>{cv.text && formatted}</span></div>
          )
        }
        else if (cv.type === "checkbox" && cv.title === "Aanvinken" && cv.text === "v") {
          return (
            <div key={createUUID()} className={`col_${index + 2}`}><img src={tickMark} alt="tick-mark" /></div>
          )
        }
        else if (cv.type === "numbers" && cv.title === "Voortgang") {
          return (
            <div key={createUUID()} className={`col_${index + 2}`}>{cv.text}%</div>
          )
        }
        else {
          return (
            <div key={createUUID()} className={`col_${index + 2}`}>{cv.text}</div>
          )
        }
      })}
      {commentIsOpen && (
        <Comments
          comments={item.updates}
          onClose={() => setCommentIsOpen(false)}
        />
      )}
    </li>
  )

}


export default Item