import React from 'react'
import { useSelector } from 'react-redux'
import { createUUID } from '../../utils'
import Item from './Item'


const Board = () => {

  const board = useSelector(state => state.board)
  const boardData = board.board?.data?.data

  return (
    <>
      <header>
        <div className="container">
          <h1 className="h1-title">{boardData.board_name}</h1>
        </div>
      </header>
      <main className="main-body-wrapper">
        <section>
          {boardData?.groups?.map(group => (
            <div key={createUUID()} className="container scroll-container">
              <ul className="tbl-holder">
                <li className="tbl-hdr">
                  <div className="col_1" style={{ color: group.color }}>
                    {group.title}
                  </div>
                  {group?.items[0]?.column_values?.map(v => v.title)?.map((title, i) => (
                    <div key={createUUID()} className={`col_${i + 2}`}>{title}</div>
                  ))}
                </li>

                {group?.items?.map(item => <Item item={item} key={createUUID()} />)}
              </ul>
            </div>
          ))}
        </section>
      </main>
    </>
  )

}


export default Board